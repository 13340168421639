import { useState, useEffect } from "react";
import { getLogo } from "../../api/chamadasApi";
import { default as LinkMaterial } from "@mui/material/Link";
import Box from "@mui/material/Box";

export default function Logo({ idHotsite = 0, isTopDashboard = false }) {
  const [logoBase64, setLogoBase64] = useState("");
  const init = async () => {
    const logoBuscado = await getLogo(idHotsite);
    setLogoBase64(logoBuscado);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    logoBase64 !== "" && (
      <LinkMaterial color="inherit" href="/">
        {isTopDashboard === true ? (
          <img
            src={"data:image/png;base64," + logoBase64}
            alt="logo"
            style={{maxHeight: '70px', width: 'auto', height: 'auto', maxWidth: "215px", objectFit: "cover"}}
            height={55}
          />
        ) : (
          <img src={"data:image/png;base64," + logoBase64} style={{maxHeight: '70px', minHeight:'70px'}} alt="logo" />
        )}
      </LinkMaterial>
    )
  );
}
