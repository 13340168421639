import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Paper,
  Typography,
  typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Subtitulo, ButtonAcaoGrande } from "../../";
import parseHtml from "html-react-parser";
import { useTheme } from "@emotion/react";
import QrCode from "../../../assets/images/qrcode.png";
import useAlert from "../../../hooks/useAlert";
import useStyle from "./style";
import Titulo from "../../Textos/Titulo";


import TipoStatusPedido from "../../../util/typesTipoStatusPedido";
import ButtonAcaoGrandeOutlined from "../../Botoes/ButtonAcaoGrande copy";

// const exemplo = {
//    Chave: 'isso é um exemplo de chave',
//    Nome: 'Primecase Demonstração',
//    CPF: '123.231.123-23',
//    Banco: 'banco tal'
// }

const Pix = ({ formaPagamento, pedido }) => {
  const theme = useTheme();
  const { Alert, showAlertSuccess, showAlertError } = useAlert();
  const classes = useStyle();
  const handlerCopy = (text) => (event) => {
    event.preventDefault();

    if (!!!text) showAlertError("Não consta codigo para ser copiado!");

    navigator.clipboard
      .writeText(text)
      .then(() => {
        showAlertSuccess("Codigo copiado com sucesso !!");
      })
      .catch((error) => {
        showAlertError(`${error.message} - R339B`);
      });
  };

  return (
    <>
      <Alert />
      {
        !!formaPagamento.formaPagamento.pix ? <>
          <Card className={classes.card} variant="outlined">
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                background: `${theme.palette.primary.main}`,
                borderRadius: 2,
              }}
            >


              <Subtitulo sx={{ color: "#fff" }}>Use o QR Code do Pix para pagar</Subtitulo>
              <Box>
                {formaPagamento.status.id === TipoStatusPedido.Cancelado ? (
                  <>
                    <Subtitulo sx={{ color: "#ddd", mt:2 }}>QR Code expirado ou cancelado</Subtitulo>
                  </>
                ) : formaPagamento.status.id ===
                  TipoStatusPedido.Pendente ? (
                  <img
                    className={classes.qrCode}
                    alt="Pix"
                    src={formaPagamento?.formaPagamento?.pix?.pixQRCode}
                    loading="lazy"
                  />
                ) : (
                  <>
                    <span>Pagamento já efetuado</span>
                  </>
                )}
              </Box>
              <Titulo color={"primary"} sx={{ fontWeight: "bold", fontSize: 30 }}>
                {pedido?.valorTotalMoedaNacional.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </Titulo>
              <Box>
                {formaPagamento.status.id !== TipoStatusPedido.Cancelado && (
                  <ButtonAcaoGrandeOutlined
                    Handle={handlerCopy(formaPagamento?.formaPagamento?.pix?.pixLinhaDigitavel)}
                    sx={{ textTransform: "inherit", fontSize: "11pt !important" }}
                    variant="outlined"
                    color="white"
                  >
                    Copiar código da chave PIX
                    <ContentCopyIcon sx={{ fontSize: 22, ml: theme.spacing(1) }} />
                  </ButtonAcaoGrandeOutlined>
                )}
              </Box>
            </CardContent>
          </Card>
        </>
          :
          <>

            <Card variant="outlined" sx={
              { borderColor: theme.palette.primary.main, borderWidth: '1px', borderRadius: 4, background: theme.palette.primary.main, minHeight: '20vh', maxWidth: '70vh', }
            }>
              <CardContent
                sx={{
                  maxHeight: '50vh',
                  overflow: 'auto'
                }}
              >
                <Subtitulo sx={{ color: "#fff" }}>Use os dados de pix para realizar o pagamento</Subtitulo>
                <hr />
               

                {!!formaPagamento.formaPagamento.descricaoHtml &&
                  <>
                    <Subtitulo sx={{ fontWeight: 'bold', mb: 2, color: "#fff" }}>{formaPagamento.formaPagamento.descricao}</Subtitulo>
                    <Subtitulo sx={{ color: '#ddd' }}>{parseHtml(formaPagamento.formaPagamento.descricaoHtml ?? "")}</Subtitulo>
                  </>
                }

              </CardContent>
            </Card>

          </>
      }
      <br />
      {formaPagamento.status.id !== TipoStatusPedido.Cancelado && !!formaPagamento.formaPagamento.pix && (
        <>
          <hr />
          <Box className={classes.card}>
            <ul className={classes.listaInformacoes}>
              <li className={classes.itemList}>
                <Typography sx={{ fontWeight: 600, width: "30em" }}>
                  Chave Pix
                </Typography>
                <Typography>
                  {formaPagamento.formaPagamento?.pix?.pixLinhaDigitavel}
                  <IconButton
                    className={classes.iconButton}
                    onClick={handlerCopy(formaPagamento?.formaPagamento?.pix?.pixLinhaDigitavel)}
                  >
                    <ContentCopyIcon
                      sx={{
                        fontSize: 20,
                        color: `${theme.palette.primary.main}`,
                      }}
                    />
                  </IconButton>{" "}
                </Typography>
              </li>
              {/* <li className={classes.itemList}>
                  <Typography><b>Nome</b></Typography>
                  <Typography><b></b>{exemplo.Nome}</Typography>
               </li>
               <li className={classes.itemList}>
                  <Typography><b>CPF</b></Typography>
                  <Typography><b></b>{exemplo.CPF}</Typography>
               </li>
               <li className={classes.itemList}>
                  <Typography><b>Banco</b></Typography>
                  <Typography><b></b>{exemplo.Banco}</Typography>
               </li> */}
            </ul>
          </Box>
        </>
      )}
    </>
  );
};

export default Pix;
