import { IVariable } from "../../interfaces/IVariable";
import { createTheme, Theme } from "@mui/material/styles";
import { darkenColor } from "../../util/helpers";

export const variables: IVariable = {
  IdHotsite: 0,
  Loja: "unida",
  UserStorage: "023DA2FB209F49C1803BBB0757D486FB==",
  UserToken: "B7BB25DD74EC40E49A6AF3BA7FA6A746==",
  UrlLoja: "https://hlg-ecommerce-par-api.primecase.com.br/api",
};

export const template: Theme = createTheme({
  palette: {
    primary: {
      main: "#e89a11",
      light: "#B799F2",
      //lighter: "#ece6f7",
      dark: darkenColor("#e89a11", 10), // Escurece em 20%
    },
    secondary: {
      main: "#7E7296",
    },
    error: {
      main: "#a91c1c",
    },
    text: {
      primary: "#222",
      //light: "#fff",
    },
    warning: {
      main: "#ff9800",
    },
    // white: {
    //   main: "#fff",
    // },
  },

  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontSize: 14,
    },
  },
});
