import { Fragment, useState } from "react";
import { Modal } from "../";
import { Box, Typography, Skeleton } from "@mui/material";
import { ButtonCustom } from "../Botoes/Button";

import parseHtml from "html-react-parser";

import styles from "./styles";

/* Parametros
   [selectedID] => state com o valor do ID selecionado
   [setSelectedID] => metodo de alteração do state vinda do pai
   [formPagamentoList] => array de pagamento vinda do pai
*/

const FormaPagamento = ({
  selectedID,
  setSelectedID,
  formPagamentoList,
  isShowLimite = false,
  isLoading,
}) => {
  const classes = styles();
  const [modalOpen, setModalOpen] = useState(0);

  const modalPagamentoOpen = (idPagamento) => (event) => {
    event.preventDefault();
    setModalOpen(idPagamento);
  };

  const handlerChangePagamento = (idPagamento) => () => {
    setSelectedID(idPagamento);
  };

  return (
    <>
      <Box>
        <Typography variant="h5" className={classes.Titulo}>
          Forma de Pagamento
        </Typography>
        
        {isShowLimite && (
          <Box className={classes.Valor}>
            <Typography>Seu limite operacional é de:</Typography>
            <Typography>USD: 100.000</Typography>
          </Box>
        )}
        
        {!isLoading ? (
          formPagamentoList?.length > 0 &&
          formPagamentoList.map((formPag) => (
            <Fragment key={formPag.id}>
              <>
                <Box
                  onClick={handlerChangePagamento(formPag.id)}
                  className={
                    selectedID == formPag.id
                      ? `${classes.FormaPagamento} ${classes.checkedPay}`
                      : `${classes.FormaPagamento}`
                  }
                >
                  <Typography>{formPag.descricao}</Typography>

                  <ButtonCustom
                    variant="text"
                    onClick={modalPagamentoOpen(formPag.id)}
                  >
                    Ver Mais
                  </ButtonCustom>
                </Box>
                <Modal
                  hasButtonClose
                  show={modalOpen == formPag.id}
                  close={() => {
                    setModalOpen(0);
                  }}
                >
                  <Typography> {parseHtml(formPag.descricaoHtml)}</Typography>
                </Modal>
              </>
            </Fragment>
          ))
        ) : (
          <>
            <Skeleton animation="wave" width={"100%"} height={100} />
          </>
        )}
      </Box>
    </>
  );
};

export default FormaPagamento;
