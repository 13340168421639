import { List, ListItem, ListItemText, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import useStyles from "./styles";
import { format } from 'date-fns'


export default function DadosEntregaPedido({ dadosEntrega = [] }) {
    const classes = useStyles();
    return (
        <>
            {
                dadosEntrega.length > 0 &&
                dadosEntrega.map((item, index) => (
                    <Fragment key={item.id + index}>
                        <List
                            sx={{
                                width: "100%",
                                pb: 0,
                            }}
                        >
                            <ListItem
                                secondaryAction={
                                    <ListItemText
                                        primary={item.transportadora ?? "Não informado"}
                                    />
                                }
                                disablePadding
                            >
                                <ListItemText className={classes.Item}>
                                    Transportadora
                                </ListItemText>
                            </ListItem>
                        </List>
                        <List
                            sx={{
                                width: "100%",
                                pb: 0,
                            }} key={item.id}
                        >
                            <ListItem
                                secondaryAction={
                                    <ListItemText
                                        primary={item.CodigoRastreio ?? "Não consta informações"}
                                    />
                                }
                                disablePadding
                            >
                                <ListItemText className={classes.Item}>
                                    Codigo Rastreio
                                </ListItemText>
                            </ListItem>
                        </List>
                        <List
                            sx={{
                                width: "100%",
                                pb: 0,
                            }} key={item.id}
                        >
                            <ListItem
                                secondaryAction={
                                    <ListItemText
                                        primary={format(new Date(item.previsaoEntrega), 'dd/MM/yyyy')}
                                    />
                                }
                                disablePadding
                            >
                                <ListItemText className={classes.Item}>
                                    Previsão de Entrage
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Fragment>
                ))
            }

            {
                dadosEntrega.length <= 0 &&
                <Typography
                    sx={{ ml: 1, mt: 1 }}
                    className={classes.Titulo}
                >
                    Não consta nenhum detalhe da sua entega
                </Typography>
            }
        </>
    );
}