import { fragment } from "react";
import styles from "./styles";
import Tooltip from "@mui/material/Tooltip";
import { Box, Typography, Grid, IconButton, Skeleton } from "@mui/material";
import { formatMoneyInStringForm } from "../../util/helpers";
import InfoIcon from "@mui/icons-material/Info";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import TiposItemCarrinho from "../../util/typesTipoItemCarrinho";
import TiposPedido from "../../util/typesTipoPedido";
import GoldIcon from "../../assets/images/bargold.ico";

import { TooltipInformacao, Cartao } from "../";
import DeleteIcon from "@mui/icons-material/Delete";

export default function ItemCambioCarrinho({
  itens,
  impostos,
  handlerRemoveItem,
  handleChangeAdicional,
  setLoading,
  loading,
  praca,
  refetchItens,
  setDadosCartao,
  dadosCartao,
}) {
  const classes = styles();

  const ValidaTipoOperacao = (item) => {
    if (item.idTipoItemCarrinho == TiposItemCarrinho.RemessaExterior)
      return true;

    if (item.idTipoItemCarrinho == TiposItemCarrinho.RecebimentoExterior)
      return true;

    return false;
  };

  return (
    <>
      <>
        <Box className={classes.Valor}>
          <Grid container>
            {itens &&
              itens.map((i, index) => (
                <fragment style={{ width: "100%" }} key={index}>
                  <Grid
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
                      Quantia:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 15,
                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {i?.valorMe} {i?.moeda?.descricao ?? ""}
                      {i.idTipoOperacaoCarrinho === TiposPedido.VendaOuro ? (
                        <Box
                          component="img"
                          className={classes.Bandeira}
                          alt="bandeira"
                          src={GoldIcon}
                        />
                      ) : (
                        <Box
                          component="img"
                          className={classes.Bandeira}
                          alt="bandeira"
                          src={`../../../../images/Bandeiras/${i.moeda.codigoBacen}.svg`}
                        />
                      )}
                      <TooltipInformacao
                        descricao="Remove a moeda Selecionada"
                        positionStart="left"
                        arrow
                      >
                        <IconButton
                          className={classes.IconButton}
                          onClick={handlerRemoveItem(i.moeda.id)}
                          sx={{ p: 0, m: 0, ml: 1 }}
                        >
                          <DeleteOutlineIcon
                            fontSize="inherit"
                            sx={{ fontSize: "1.2rem" }}
                            color="inherit"
                          />
                        </IconButton>
                      </TooltipInformacao>
                    </Typography>
                  </Grid>

                  <Grid
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: 14 }}>
                      Taxa do ouro (1g):{" "}
                      <Tooltip title="Taxa equivalente para barra com 1g">
                        <IconButton sx={{ p: 0 }}>
                          <InfoIcon sx={{ fontSize: 15 }} />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      R${" "}
                      {formatMoneyInStringForm(
                        i.valorTaxaAplicada ?? 0,
                        i.moeda.numeroCasaDecimais
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: 14 }}>
                      Multiplicador:{" "}
                      <Tooltip
                        title={`Multiplica a grama da barra pelo valor da taxa exemplo: taxa: (${formatMoneyInStringForm(
                          i?.valorTaxaAplicada ?? 10,
                          2
                        )}) x multiplicador: (${formatMoneyInStringForm(
                          i?.moeda?.multiplicador ?? 2,
                          2
                        )})`}
                      >
                        <IconButton sx={{ p: 0 }}>
                          <InfoIcon sx={{ fontSize: 15 }} />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      x{" "}
                      {formatMoneyInStringForm(i?.moeda?.multiplicador ?? 0, 2)}
                    </Typography>
                  </Grid>


                  <Grid
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: 14 }}>
                      VET:{" "}
                      <Tooltip title="Valor total da operação">
                        <IconButton sx={{ p: 0 }}>
                          <InfoIcon sx={{ fontSize: 15 }} />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      R$ {formatMoneyInStringForm(i.valorVet ?? 0, 5)}
                    </Typography>
                  </Grid>

                  {loading ? (
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      flexGrow={1}
                      sx={{ mt: "-1rem" }}
                      justifyContent={"space-between"}
                    ></Grid>
                  ) : (
                    <>
                      {ValidaTipoOperacao(i) ? (
                        impostos &&
                        impostos.map((i, index) => (
                          <fragment key={index}>
                            <Grid sx={{ display: "flex", mb: 1 }} xs={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                {i.idFinalidade == 0 ? (
                                  <Typography sx={{ fontSize: 14 }}>
                                    {i.descricao}
                                    <Tooltip title="Valor cobrado para realizar as remessas para o exterior. A taxa varia de acordo com o banco.">
                                      <IconButton sx={{ p: 0 }}>
                                        <InfoIcon sx={{ fontSize: 15 }} />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                ) : (
                                  <>
                                    <Typography sx={{ fontSize: 14 }}>
                                      Aliquota {i.descricao}({i.aliquota}%):
                                      <Tooltip title="Esse imposto é o cobrado em operações financeiras como crédito, câmbio e seguros.">
                                        <IconButton sx={{ p: 0 }}>
                                          <InfoIcon sx={{ fontSize: 15 }} />
                                        </IconButton>
                                      </Tooltip>
                                    </Typography>
                                  </>
                                )}
                                <Typography>
                                  R$ {formatMoneyInStringForm(i.valor ?? 0, 2)}
                                </Typography>
                              </Box>
                            </Grid>
                          </fragment>
                        ))
                      ) : (
                        <>
                          {i.idTipoOperacaoCarrinho !=
                            TiposPedido.VendaOuro && (
                              <Grid xs={12}>
                                <Box
                                  className={classes.Taxa}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {impostos[index]?.idFinalidade == 0 ? (
                                    <Typography sx={{ mr: 1 }}>
                                      {impostos[index].descricao} :
                                    </Typography>
                                  ) : (
                                    <Typography color="text.secondary">
                                      Aliquota {impostos[index]?.descricao}(
                                      {impostos[index]?.aliquota}%):
                                    </Typography>
                                  )}
                                  <Typography>
                                    R${" "}
                                    {formatMoneyInStringForm(
                                      impostos[index]?.valor ?? 0,
                                      2
                                    )}
                                  </Typography>
                                </Box>
                              </Grid>
                            )}
                        </>
                      )}
                    </>
                  )}

                  {i.moeda.isEspecie == false &&
                    i.idTipoOperacaoCarrinho != TiposPedido.VendaOuro && (
                      <Cartao
                        item={i}
                        praca={praca}
                        setLoading={setLoading}
                        loading={loading}
                        refetchItens={refetchItens}
                        isAdicional={i.emitirCartaoAdicional}
                        setDadosCartao={setDadosCartao}
                        dadosCartao={dadosCartao}
                        isRecarga={
                          i.idTipoItemCarrinho ===
                          TiposItemCarrinho.RecargaCartao
                        }
                        onCartaoAdicionalChange={(item, possuiAdicional) => {
                          handleChangeAdicional(item, possuiAdicional);
                        }}
                      />
                    )}
                </fragment>
              ))}
          </Grid>
        </Box>
      </>
    </>
  );
}
