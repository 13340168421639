import api from "../index";

import { configurationVariables } from "../../environment/index";
const idHotsite = parseInt(configurationVariables.IdHotsite);

export const getVitrineEnvio = async (idCliente) => {
  try {
    const { data: response, status } = await api.get(
      `/Vitrine/GetVitrineEnvio?idHotsite=${
        idHotsite ?? 0
      }&idCliente=${idCliente}`
    );
    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "R6EB7");
  }
};

export const getValoresItemCarrinho = async (
  idVitrine,
  idItemVitrine,
  idNatureza,
  pass
) => {
  try {
    if (!!idVitrine && !!idItemVitrine && !!idNatureza) {
      const { data: response, status } = await api.get(
        `/Vitrine/GetValoresItemVitrineRemessa?idVitrine=${idVitrine}&idItemVitrine=${idItemVitrine}&idNatureza=${idNatureza}&idHotsite=${
          idHotsite ?? 0
        }`
      );

      return {
        status,
        errorMessage: response.errorMessage,
        data: response.data,
      };
    }
  } catch (error) {
    throw new Error(error + "R4BF5");
  }
};

export const getValoresItemCarrinhoTurismo = async (
  idVitrine,
  idItemVitrine,
  idPraca
) => {
  try {
    if ((!!idVitrine, !!idItemVitrine, !!idPraca)) {
      const { data: response, status } = await api.get(
        `/Vitrine/GetValoresItemTurismo?idVitrine=${idVitrine}&idItemVitrine=${idItemVitrine}&idPraca=${idPraca}&idHotsite=${idHotsite}`
      );
      return {
        status,
        errorMessage: response.errorMessage,
        data: response.data,
      };
    }
  } catch (error) {
    throw new Error(error + "RB2BD");
  }
};

export const getVitrineRecebimento = async (idCliente) => {
  try {
    const { data: response, status } = await api.get(
      `/Vitrine/GetVitrineRecebimento?idHotsite=${
        idHotsite ?? 0
      }&idCliente=${idCliente}`
    );
    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "R37C4");
  }
};

export const getVitrineTurismo = async (
  idCliente,
  idTipoOperacao,
  isEspecie
) => {
  try {
    const { data: response, status } = await api.get(
      `/Vitrine/GetVitrineTurismo?idHotsite=${
        idHotsite ?? 0
      }&idCliente=${idCliente}&idTipoOperacao=${idTipoOperacao}&isEspecie=${isEspecie}`
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "R5F4E");
  }
};

export const getVitrineOuro = async (idCliente) => {
  try {
    const { data: response, status } = await api.get(
      `/Vitrine/GetVitrineOuro?idHotsite=${
        idHotsite ?? 0
      }&idCliente=${idCliente}`
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "R5F4E");
  }
};

export const getValoresItemOuro = async (idVitrine, idItemVitrine, idPraca) => {
  try {
    if ((!!idVitrine, !!idItemVitrine, !!idPraca)) {
      const { data: response, status } = await api.get(
        `/Vitrine/GetValoresItemOuro?idVitrine=${idVitrine}&idItemVitrine=${idItemVitrine}&idPraca=${idPraca}&idHotsite=${
          idHotsite ?? 0
        }`
      );
      return {
        status,
        errorMessage: response.errorMessage,
        data: response.data,
      };
    }
  } catch (error) {
    throw new Error(error + "R4BF5");
  }
};

export const getAllVitrinesIsHabilitada = async () => {
  try {
    const { data: response, status } = await api.get(
      `/Vitrine/GetVitrinesDisponiveis?idHotsite=${idHotsite ?? 0}`
    );
    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "R0PQN");
  }
};
