import { Theme } from "@mui/material/styles";
import { IVariable } from "../interfaces/IVariable";
import {
  variables as VariablesDuoDigital,
  template as TemplateDuoDigital,
} from "./duodigital";
import {
  variables as VariablesLevyCam,
  template as TemplateLevyCam,
} from "./levycam";
import {
  variables as VariablesUnida,
  template as TemplateUnida,
} from "./unida";
import {
  variables as VariableBrokerBrasil,
  template as TemplateBrokerBrasil,
} from "./brokerbrasil";
import { variables as VariableFair, template as TemplateFair } from "./fair";
import {
  variables as VariableFourTrade,
  template as TemplateFourTrade,
} from "./fourtrade";
import {
  variables as VariableGetMoney,
  template as TemplateGetMoney,
} from "./getmoney";
import { variables as VariableFram, template as TemplateFram } from "./fram";

let configurationVariables: IVariable;
let configurationTheme: Theme;

switch (process.env.REACT_APP_LOJA) {
  case "duodigital":
    configurationVariables = VariablesDuoDigital;
    configurationTheme = TemplateDuoDigital;
    break;
  case "levycam":
    configurationVariables = VariablesLevyCam;
    configurationTheme = TemplateLevyCam;
    break;
  case "unida":
    configurationVariables = VariablesUnida;
    configurationTheme = TemplateUnida;
    break;
  case "brokerbrasil":
    configurationVariables = VariableBrokerBrasil;
    configurationTheme = TemplateBrokerBrasil;
    break;
  case "fair":
    configurationVariables = VariableFair;
    configurationTheme = TemplateFair;
    break;
  case "fourtrade":
    configurationVariables = VariableFourTrade;
    configurationTheme = TemplateFourTrade;
    break;
  case "getmoney":
    configurationVariables = VariableGetMoney;
    configurationTheme = TemplateGetMoney;
    break;
  case "fram":
    configurationVariables = VariableFram;
    configurationTheme = TemplateFram;
    break;
  default:
    configurationVariables = VariablesDuoDigital;
    configurationTheme = TemplateDuoDigital;
    break;
}

export { configurationVariables, configurationTheme };
