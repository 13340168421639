
import api from "../";

//! Pega todas as formas de pagamento
export const GetFormaPagamento = async (idTipoOperacao, pass) => {

   try {
      const { data: response, status } = await api.get(`/FormaPagamento/GetByIdTipoPedido?idTipoPedido=${idTipoOperacao}`)

      return {
         status,
         errorMessage: response.errorMessage,
         data: response.data,
      }
   } catch (error) {
      throw new Error(error + "R3BY5");
   }
}

//! Adiciona a forma de pagamento selecionada no carrinho Temp
export const AddCarrinhoTempConfigurePagamento = async (formData) => {
   try {
      const { data: response } =
         await api.post(`/CarrinhoTemp/ConfiguraPagamento?idCliente=${formData?.userID}&idOrigem=${3}`, {
            idFormaPagamento: formData?.idFormaPagamento,
            descricao: formData?.descricao,
         });

      return response;
   } catch (error) {
      throw new Error(error + " RG77R");
   }
}

//! Pega a forma de pagamento com base em seu ID
export const GetFormaPagamentoByID = async (idFormaPagamento) => {
   try {
      const { data: response, status } = await api.get(`/FormaPagamento/GetById?id=${idFormaPagamento}`)

      return {
         status,
         errorMessage: response.errorMessage,
         data: response.data,
      }
   } catch (error) {
      throw new Error(error + "RP64E");
   }
}

//! Pega a forma de pagamento com base em seu ID
export const GetCanalBancarioExteriorByIDMoeda = async (idMoeda) => {
   try {
      const { data: response, status } = await api.get(`/FormaPagamento/GetCanalBancarioExteriorByIDMoeda?idMoeda=${idMoeda}`)

      return {
         status,
         errorMessage: response.errorMessage,
         data: response.data,
      }
   } catch (error) {
      throw new Error(error + "R78E8");
   }
}

export const EnviarEmailRemetenteExterior = async (data) => {
   try {

      const response = await api.post(
         `/FormaPagamento/EnviaEmailCanalBancarioExteriorByIDMoeda?idMoeda=${data?.idMoeda}&email=${data?.email}`
      );

   } catch (error) {
      throw new Error(error + " RABC6");
   }
};

