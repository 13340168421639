import { useNavigate } from "react-router-dom";
import DadoBancarioItem from "../DadoBancarioItem";
import ParticipacaoExternaItem from "../ParticipacaoExternaItem";
import ParticipacaoJuridicaItem from "../ParticipacaoJuridicaItem";
import RepresentanteItem from "../RepresentanteItem";
import SocioItem from "../SocioItem";
import QuestionarioFatca from "../QuestionarioFatca";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import DadosBasicos from "../DadosBasicos";
import EnderecoPrincipal from "../EnderecoPrincipal";
import Documento from "../Documento";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { useAuth } from "../../../hooks/AuthContext";
import MenuDashboard from "../../MenuDashboard";
import { useEffect, useState } from "react";
import { Grid, Box, Divider, Button } from "@mui/material";
import Titulo from "../../Textos/Titulo";
import Subtitulo from "../../Textos/Subtitulo";
import theme from "../../Paleta/theme";
import StepLine from "../../Steps/StepLine";
import Skeleton from "@mui/material/Skeleton";

import Seletor from "../../Seletor";
import useStyle from "./styles";
import {
  saveDadosBasicos,
  saveDadoBancario,
  saveQuestionarioFatca,
  saveSocio,
  saveParticipacaoExterna,
  saveRepresentante,
  saveParticipacaoJuridica,
  saveDocumentos,
  saveEnderecoCadastro,
  getInit,
} from "../../../api/Cadastro";
import useAlert from "../../../hooks/useAlert";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { ButtonCustom } from "../../Botoes/Button";
import TypesErroLoginRedirect from "../../../util/typesErrorLogin";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function FormularioLimiteOperacional({
  perfisLimiteOperacional,
  idPerfil,
  label,
  title,
}) {
  const navigation = useNavigate();
  const { user, token, updateUser, signOut } = useAuth();
  const [endereco, setEndereco] = useState({});
  const [dadosBasicos, setDadosBasicos] = useState({});
  const [dadoBancario, setDadoBancario] = useState({});
  const [questionarioFatca, setQuestionarioFatca] = useState({});
  const [socio, setSocio] = useState({});
  const [participacaoExterna, setParticipacaoExterna] = useState({});
  const [representante, setRepresentante] = useState({});
  const [participacaoJuridica, setParticipacaoJuridica] = useState({});
  const [documentos, setDocumentos] = useState([]);
  const { Alert, showAlertSuccess, showAlertError } = useAlert();
  const [stepLine, setStepLine] = useState([]);
  const [stepItem, setStepItem] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [idPerfilLimiteOperacional, setIdPerfilLimiteOperacional] =
    useState(idPerfil);
  const [nomeCliente, setNomeCliente] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);

  useEffect(() => {
    init(user.id, idPerfilLimiteOperacional);
  }, [user, idPerfilLimiteOperacional]);

  const init = async (idCliente, idPerfilLimiteOperacional) => {
    getInit(idCliente, idPerfilLimiteOperacional)
      .then((res) => {
        if (!!res?.errorMessage) {
          throw new Error(res.errorMessage + " RJ9N0");
        }
        setStepLine(res.data.steps);
        setStepItem(res.data.steps[0]);
      })
      .catch((error) => {
        console.error("Primecase ", error.message);
        showAlertError(error.message);
        setTimeout(() => {
          signOut();
        }, 200);
      });
  };

  const handleBack = async () => {
    setIsLoading(true);

    setTimeout(() => {
      const nextIndex = stepItem.index - 1;

      const rest = stepLine.map((s) => {
        if (s.index === nextIndex) {
          setStepItem(s);
          return s;
        }

        return s;
      });

      setStepLine(rest);

      setIsLoading(false);
    }, 2000);
  };

  const changeDataPropriedades = (
    array,
    propriedade,
    isObrigatorio = false,
    newValue
  ) => {
    let newArray = array.map((i) => {
      if (i.propriedade === propriedade) {
        i.obrigatorio = isObrigatorio;
        i.valor = newValue ?? i.valor;

        return i;
      }
      return i;
    });

    return newArray;
  };

  const handleNext = async () => {
    setIsLoading(true);

    try {
      let response = null;

      switch (stepItem.value) {
        case 0:
          if (
            !!dadosBasicos?.dadosPessoaFisica?.campos?.length &&
            dadosBasicos?.dadosPessoaFisica?.idEstadoCivil === 2
          ) {
            let newCampos = changeDataPropriedades(
              dadosBasicos.dadosPessoaFisica.campos,
              "CpfCojugue",
              true
            );
            setDadosBasicos((prevState) => {
              return {
                ...prevState,
                dadosPessoaFisica: {
                  ...prevState.dadosPessoaFisica,
                  campos: newCampos,
                },
              };
            });
          } else if (!!dadosBasicos?.dadosPessoaFisica?.campos?.length) {
            let newCampos = changeDataPropriedades(
              dadosBasicos.dadosPessoaFisica.campos,
              "CpfCojugue",
              false,
              ""
            );

            setDadosBasicos((prevState) => {
              return {
                ...prevState,
                dadosPessoaFisica: {
                  ...prevState.dadosPessoaFisica,
                  campos: newCampos,
                },
              };
            });
          }

          response = await saveDadosBasicos(
            dadosBasicos,
            idPerfilLimiteOperacional,
            stepItem.isFinish
          );

          if (nomeCliente) {
            updateUser({ ...user, nome: nomeCliente }, token);
          }
          break;
        case 1:
          response = await saveDadoBancario(
            dadoBancario,
            idPerfilLimiteOperacional,
            stepItem.isFinish
          );
          break;
        case 2:
          response = await saveSocio(
            socio,
            idPerfilLimiteOperacional,
            stepItem.isFinish
          );
          break;
        case 3:
          response = await saveParticipacaoJuridica(
            participacaoJuridica,
            idPerfilLimiteOperacional,
            stepItem.isFinish
          );
          break;
        case 4:
          response = await saveRepresentante(
            representante,
            idPerfilLimiteOperacional,
            stepItem.isFinish
          );
          break;
        case 5:
          response = await saveParticipacaoExterna(
            participacaoExterna,
            idPerfilLimiteOperacional,
            stepItem.isFinish
          );
          break;
        case 6:
          response = await saveQuestionarioFatca(
            questionarioFatca,
            idPerfilLimiteOperacional,
            stepItem.isFinish
          );
          break;
        case 7:
          response = await saveDocumentos(
            documentos,
            idPerfilLimiteOperacional,
            stepItem.isFinish
          );
          break;
        case 8:
          if (!endereco.cep)
            throw new Error(
              "É necessário preencher os dados do endereço para seguir."
            );

          response = await saveEnderecoCadastro(
            endereco,
            idPerfilLimiteOperacional,
            stepItem.isFinish
          );
          break;
        default:
          break;
      }

      if (response.errorMessage) {
        showAlertError(response.errorMessage);
      } else {
        if (stepItem.isFinish) {
          showAlertSuccess("Cadastro finalizado!");
          setTimeout(() => {
            updateUser({ ...user, idTipoStatusCliente: 3 }, token);
            navigation("/MinhaConta", { replace: false });
          }, 2000);
        } else {
          showAlertSuccess("Dados atualizados com sucesso!");

          setTimeout(() => {
            const nextIndex = stepItem.index + 1;

            const rest = stepLine.map((s) => {
              if (s.index === nextIndex) {
                setStepItem(s);
                return s;
              }

              return s;
            });

            setStepLine(rest);
          }, 2000);
        }
      }
    } catch (error) {
      showAlertError(error.message);
    }
    setTimeout(() => setIsLoading(false), 2000);
  };

  const handlerChangePerfil = async (e) => {
    setIdPerfilLimiteOperacional(e);
  };

  const classes = useStyle();

  return (
    <>
      <Alert />

      <Box sx={{ flexGrow: 1, m: 2 }}>
        <Grid container spacing={1} sx={{ alignItems: "center" }}>
          <Grid item lg={6} className={classes.Topo}>
            <Item>
              {" "}
              <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                <Titulo
                  sx={{ fontWeight: "bold", color: "black", fontSize: "22px" }}
                  label={label}
                ></Titulo>
                <Tooltip title={title}>
                  <IconButton>
                    <InfoIcon fontSize="small" color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Subtitulo
                sx={{ mt: 1 }}
                label="Escolha seu limite operacional, depois você poderá solicitar um aumento."
              ></Subtitulo>
              <Grid
                sx={{
                  mt: 3,
                }}
              >
                {isLoading ? (
                  <Skeleton
                    animation="wave"
                    sx={{ ml: 0, mr: 3 }}
                    height={60}
                    width={150}
                  />
                ) : (
                  stepLine && (
                    <StepLine
                      steps={stepLine}
                      stepItem={stepItem}
                      orientation="horizontal"
                    />
                  )
                )}
              </Grid>
            </Item>
          </Grid>

          <Grid
            item
            lg={3}
            xs={12}
            sx={{ maxWidth: "100%", display: "flex", alignItems: "center" }}
            className={classes.seletor}
          >
            {" "}
            {idPerfilLimiteOperacional > 0 &&
              (isLoading ? (
                <Skeleton animation="wave" width={"100%"} height={60} />
              ) : (
                <Seletor
                  sx={{ mt: 0, m: 0, ml: 1, width: "100%" }}
                  setSelect={handlerChangePerfil}
                  list={perfisLimiteOperacional}
                  size="small"
                  fullWidth={true}
                  label="Escolha o Limite Operacional"
                  value={idPerfilLimiteOperacional}
                  // isLoading={isLoading}
                />
              ))}
            <Item>
              <Tooltip title="Defina seu limite operacional da plataforma, depois você poderá solicitar o aumento.">
                <IconButton>
                  <InfoIcon fontSize="small" color="primary" />
                </IconButton>
              </Tooltip>
            </Item>
          </Grid>
        </Grid>
      </Box>

      <Grid container>
        <Grid xs={12} lg={9} className={classes.Dados}>
          {Object.keys(stepItem).length > 0 && stepItem.value === 0 && (
            <Box className={classes.Box}>
              <DadosBasicos
                idCliente={user.id}
                idPerfilLimiteOperacional={idPerfilLimiteOperacional}
                setDada={setDadosBasicos}
                isLoading={isLoading}
                setNome={setNomeCliente}
                setDisabledButton={setDisabledButton}
              />
            </Box>
          )}
          {Object.keys(stepItem).length > 0 && stepItem.value === 1 && (
            <Box className={classes.Box}>
              <DadoBancarioItem
                idCliente={user.id}
                idPerfilLimiteOperacional={idPerfilLimiteOperacional}
                setDada={setDadoBancario}
                isLoading={isLoading}
              />
            </Box>
          )}

          {Object.keys(stepItem).length > 0 && stepItem.value === 2 && (
            <Box className={classes.Box}>
              <SocioItem
                idCliente={user.id}
                idPerfilLimiteOperacional={idPerfilLimiteOperacional}
                setDada={setSocio}
                isLoading={isLoading}
              />
            </Box>
          )}

          {Object.keys(stepItem).length > 0 && stepItem.value === 3 && (
            <Box className={classes.Box}>
              <ParticipacaoJuridicaItem
                idCliente={user.id}
                idPerfilLimiteOperacional={idPerfilLimiteOperacional}
                setDada={setParticipacaoJuridica}
                isLoading={isLoading}
              />
            </Box>
          )}

          {Object.keys(stepItem).length > 0 && stepItem.value === 4 && (
            <Box className={classes.Box}>
              <RepresentanteItem
                idCliente={user.id}
                idPerfilLimiteOperacional={idPerfilLimiteOperacional}
                setDada={setRepresentante}
                isLoading={isLoading}
              />
            </Box>
          )}

          {Object.keys(stepItem).length > 0 && stepItem.value === 5 && (
            <Box className={classes.Box}>
              <ParticipacaoExternaItem
                idCliente={user.id}
                idPerfilLimiteOperacional={idPerfilLimiteOperacional}
                setDada={setParticipacaoExterna}
                isLoading={isLoading}
              />
            </Box>
          )}

          {Object.keys(stepItem).length > 0 && stepItem.value === 6 && (
            <Box className={classes.Box}>
              <QuestionarioFatca
                idCliente={user.id}
                idPerfilLimiteOperacional={idPerfilLimiteOperacional}
                setDada={setQuestionarioFatca}
                isLoading={isLoading}
              />
            </Box>
          )}

          {Object.keys(stepItem).length > 0 && stepItem.value === 7 && (
            <Box className={classes.Box}>
              <Documento
                user={user}
                idPerfilLimiteOperacional={idPerfilLimiteOperacional}
                setDados={setDocumentos}
                isLoading={isLoading}
              />
            </Box>
          )}

          {Object.keys(stepItem).length > 0 && stepItem.value === 8 && (
            <Box className={classes.Box}>
              <EnderecoPrincipal
                user={user}
                idPerfilLimiteOperacional={idPerfilLimiteOperacional}
                isLoading={isLoading}
                setData={setEndereco}
              />
            </Box>
          )}

          <Box sx={{ zIndex: 2, p: 2, pb: 8 }} className={classes.Botoes}>
            {stepItem.value > 0 &&
              (isLoading ? (
                <Skeleton
                  animation="wave"
                  width={100}
                  height={60}
                  sx={{ mr: 2 }}
                />
              ) : (
                <Button
                  size="medium"
                  color="primary"
                  onClick={handleBack}
                  variant="outlined"
                >
                  <ArrowBackIcon />
                  Voltar
                </Button>
              ))}
            <div></div>
            {isLoading && <Skeleton animation="wave" width={100} height={60} />}
            {!isLoading && (
              <ButtonCustom
                size="medium"
                variant="contained"
                sx={{ width: "30%", mr: 1 }}
                disabled={disabledButton}
                onClick={handleNext}
              >
                Próximo
              </ButtonCustom>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
