import { useState } from "react";
import { Box, Button } from "@mui/material";
import { useQuery, useQueryClient, useMutation } from "react-query";
import useAlert from "../../hooks/useAlert";
import { FORMAPAGEMENTOOPERACAO, DETALHEPEDIDOCOMERCIAL } from "../../util/typesReactQuery";
import { GetFormaPagamento } from "../../api/FormaPagamento";
import { atualizarFormaPagamento } from '../../api/Cambio';
import FormaPagamento from "../FormaPagamento";

const EdicaoFormaPagamento = ({ pedido, idCliente }) => {
  const queryClient = useQueryClient();
  const { Alert, showAlertSuccess, showAlertError } = useAlert();
  const [selectedID, setSelectedID] = useState(pedido?.formaPagamento?.id ?? 0);
  const { tipoPedido, id } = pedido ?? {};

  // Pega as formas de pagamento
  const { data: formasPagamento } = useQuery(
    [FORMAPAGEMENTOOPERACAO, tipoPedido.id],
    () => GetFormaPagamento(tipoPedido.id),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (!data || data.data.length <= 0) {
          showAlertError(
            "Algo deu errado , informe o seguinte erro ao suporte: não foi possivel encontrar formas de pagamento - RWSPO"
          );
        }
        if (data.errorMessage && data.errorMessage !== "") {
          showAlertError(
            "Algo deu errado , informe o seguinte erro ao suporte: ",
            data.errorMessage + " - RDXCW"
          );
        }
      },
      retry: false,
    }
  );

  const mutationAlterarFormaPagamento = useMutation(atualizarFormaPagamento, {
    //função chamada quando concluido a chamada da api
    onSuccess: (response) => {
      if (response.errorMessage && response.errorMessage.length > 0) {
        showAlertError(response.errorMessage);
      } else {
        showAlertSuccess(
          "Alteração de forma de pagamento efetuada com sucesso"
        );
      }
    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      showAlertError(
        "Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - " +
        err
      );
    },
    //função chamada quando é concluido ou da erro
    onSettled: async () => {
      // queryClient.invalidateQueries(DETALHEPEDIDO);
      queryClient.refetchQueries(DETALHEPEDIDOCOMERCIAL);
    },
  });

  const handlerSubmit = (e) => {
    mutationAlterarFormaPagamento.mutate({
      idCliente: idCliente,
      idPedido: id,
      idFormaPagamento: selectedID,
    });
  };

  return (
    <>
      <Alert />
      {formasPagamento && (
        <Box>
          <FormaPagamento
            selectedID={selectedID}
            setSelectedID={setSelectedID}
            formPagamentoList={formasPagamento.data}
          />

          <Button
            style={{ marginTop: 25 }}
            variant="contained"
            fullWidth
            type="submit"
            onClick={handlerSubmit}
          >
            Salvar
          </Button>
        </Box>
      )}
    </>
  );
};

export default EdicaoFormaPagamento;
